//import Screenshot1 from '../../assets/image/saasClassic/screen-1.png';

import UniversityChicago from '../common/assets/Testimonials/uchicago 1.png';
import LSE from '../common/assets/Testimonials/LSE.png';
import BrownUniversity from '../common/assets/Testimonials/Brown University.png';
import cornell from '../common/assets/Testimonials/cornell.jpg';
import AuthorOne from '../common/assets/author-2.jpg';
import AuthorTwo from '../common/assets/author-2.jpg';
import AuthorThree from '../common/assets/author-3.jpg';
import testiImage from '../common/assets/aboutthetest2.png';
import step1 from '../common/assets/step1.png';
import step2 from '../common/assets/step2.png';
import step3 from '../common/assets/step3.png';
import feature1 from '../common/assets/Features/feature1.png';
import feature2 from '../common/assets/Features/feature2.png';
import feature3 from '../common/assets/Features/feature3.png';
import feature4 from '../common/assets/Features/feature4.png';
import feature5 from '../common/assets/Features/feature5.png';
import feature6 from '../common/assets/Features/feature6.png';

//import AuthorThree from '../../assets/image/saasClassic/author-3.jpg';

import Logo from '../common/assets/gennextlogo.png';
import appleStore from '../common/assets/apple-store.png';
import googlePlay from '../common/assets/google-play.png';


import uni1 from '../common/assets/Universities logo/uni1.png';
import uni2 from '../common/assets/Universities logo/uni2.png';
import uni3 from '../common/assets/Universities logo/uni3.png';
import uni4 from '../common/assets/Universities logo/uni4.png';
import uni5 from '../common/assets/Universities logo/uni5.png';
import uni6 from '../common/assets/Universities logo/uni6.png';
import uni7 from '../common/assets/Universities logo/uni7.png';
import uni8 from '../common/assets/Universities logo/uni8.png';
import uni9 from '../common/assets/Universities logo/uni9.png';
import uni10 from '../common/assets/Universities logo/uni10.png';
import uni11 from '../common/assets/Universities logo/uni11.png';
import uni12 from '../common/assets/Universities logo/uni12.png';
import uni13 from '../common/assets/Universities logo/uni13.png';
import uni14 from '../common/assets/Universities logo/uni14.png';
import uni15 from '../common/assets/Universities logo/uni15.png';
import uni16 from '../common/assets/Universities logo/uni16.png';
import uni17 from '../common/assets/Universities logo/uni17.png';
import uni18 from '../common/assets/Universities logo/uni18.png';
import uni19 from '../common/assets/Universities logo/uni19.png';

import acc1 from '../common/assets/Accredations/acc1.png';
import acc2 from '../common/assets/Accredations/acc2.png';
import acc3 from '../common/assets/Accredations/acc3.png';
import acc4 from '../common/assets/Accredations/acc4.png';
import acc5 from '../common/assets/Accredations/acc5.png';
import acc6 from '../common/assets/Accredations/acc6.png';
import acc7 from '../common/assets/Accredations/acc7.png';
import acc8 from '../common/assets/Accredations/acc8.png';

export const bannerData = {
  news: 'NEWS',
  newsLabel: 'We have updated our term & condition policy',
  title: <>Boost your chances to get into top universities with JenNext Mentors:<br /> Gain Expert Advice Today!</>,
  text: <>Feeling Overwhelmed? Our <strong> Expert Mentors</strong> Simplify Your Journey to Global Education. From profile evaluation to scholarship support, JenNext is your one-stop solution for all things study abroad.</>,
  button: [
    {
      id: 1,
      title: 'Google Play',
      thumb: googlePlay,
      link: 'https://play.google.com/store',
    },
    {
      id: 2,
      title: 'App Store',
      thumb: appleStore,
      link: 'https://www.apple.com/sg/app-store/',
    },
  ],
};

export const FOOTER_DATA = {
  logo: Logo,
  menu: [
    {
      link: '#',
      label: 'Support',
    },
    {
      link: '#',
      label: 'About Us',
    },
    {
      link: '#',
      label: 'Privacy',
    },
    {
      link: '#',
      label: 'Contact',
    },
  ],
  social: [
    {
      icon: Logo,
      link: '#',
    },
  ],
};


//export const MENU_ITEMS = [{
//        label: 'Home',
//        path: '#banner_section',
//        offset: '0',
//    },
//    {
//        label: 'Screenshot',
//        path: '#screenshot_section',
//        offset: '0',
//    },
//    {
//        label: 'Feature',
//        path: '#feature_section',
//        offset: '0',
//    },
//    {
//        label: 'Pricing',
//        path: '#pricing_section',
//        offset: '0',
//    },
//    {
//        label: 'Testimonial',
//        path: '#testimonial_section',
//        offset: '0',
//    },
//];

//export const SERVICE_ITEMS = [{
//        icon: 'flaticon-stopwatch-1',
//        title: 'Fast Performance',
//    },
//    {
//        icon: 'flaticon-prototype',
//        title: 'Prototyping',
//    },
//    {
//        icon: 'flaticon-code',
//        title: 'Coade Export',
//    },
//    {
//        icon: 'flaticon-vectors',
//        title: 'Vector Editing',
//    },
//    {
//        icon: 'flaticon-export',
//        title: 'Export Presets',
//    },
//];

export const MONTHLY_PRICING_TABLE = [{
  name: 'Step 1',
  description: <><span style={{color: '#CD121B'}}>Take the Assessment</span></>,
  price: (
    <>
      Begin your journey by taking our meticulously designed test. Take out 5 minutes of your time to take our assessment and figure out your strengths and areas of improvement.<br /><br /><br /><br />
      <img src={ step1 } />
    </>
  ),
},
{
  name: 'Step 2',
  description: <><span style={{color: '#CD121B'}}>Receive your personalized Report</span></>,
  price: (
    <>
      Our assessment provides a clear indication of your preparedness for studying abroad. Our comprehensive report outlines your performance across different areas.<br /><br />
      <img src={ step2 } />
    </>
  ),
},
{
  name: 'Step 3',
  description: <><span style={{color: '#CD121B'}}>Expert Counseling & Recommendations</span></>,
  price: (
    <>
      The most crucial step - schedule a one-on-one session with our expert mentors to discuss your assessment results and receive tailored advice for your study abroad journey.<br /><br /><br /><br />
      <img src={ step3 } />
    </>
  ),
},
];

export const YEARLY_PRICING_TABLE = [{
  name: 'Basic Account',
  description: 'For a single client or team who need to build website ',
  price: '$0',
  priceLabel: 'Only for first month',
  buttonLabel: 'Start for free',
  url: '#',
  listItems: [{
    content: 'Drag & Drop Builder',
  },
  {
    content: '1,000s of Templates Ready',
  },
  {
    content: 'Blog Tools',
  },
  {
    content: 'eCommerce Store ',
  },
  {
    content: '30+ Webmaster Tools',
  },
  ],
},
{
  name: 'Business Account',
  description: 'For Small teams or group who need to build website ',
  price: '$6.00',
  priceLabel: 'Per month & subscription yearly',
  buttonLabel: 'Register Now',
  url: '#',
  trialButtonLabel: 'Or Start 14 Days trail',
  trialURL: '#',
  listItems: [{
    content: 'Unlimited secure storage',
  },
  {
    content: '2,000s of Templates Ready',
  },
  {
    content: 'Blog Tools',
  },
  {
    content: '24/7 phone support',
  },
  {
    content: '50+ Webmaster Tools',
  },
  ],
},
{
  name: 'Premium Account',
  description: 'For Large teams or group who need to build website ',
  price: '$9.99',
  priceLabel: 'Per month & subscription yearly',
  buttonLabel: 'Register Now',
  url: '#',
  trialButtonLabel: 'Or Start 14 Days trail',
  trialURL: '#',
  listItems: [{
    content: 'Drag & Drop Builder',
  },
  {
    content: '3,000s of Templates Ready',
  },
  {
    content: 'Advanced branding',
  },
  {
    content: 'Knowledge base support',
  },
  {
    content: '80+ Webmaster Tools',
  },
  ],
},
];

//export const FAQ_DATA = [{
//        expend: true,
//        title: 'How to contact with Customer Service?',
//        description: 'Our Customer Experience Team is available 7 days a week and we offer 2 ways to get in contact.Email and Chat . We try to reply quickly, so you need not to wait too long for a response!. ',
//    },
//    {
//        title: 'App installation failed, how to update system information?',
//        description: 'Please read the documentation carefully . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum . ',
//    },
//    {
//        title: 'Website reponse taking time, how to improve?',
//        description: 'At first, Please check your internet connection . We also have some online  video tutorials regarding this issue . If the problem remains, Please Open a ticket in the support forum .',
//    },
//    {
//        title: 'New update fixed all bug and issues?',
//        description: 'We are giving the update of this theme continuously . You will receive an email Notification when we push an update. Always try to be updated with us .',
//    },
//];

//export const FOOTER_WIDGET = [{
//        title: 'About Us',
//        menuItems: [{
//                url: '#',
//                text: 'Support Center',
//            },
//            {
//                url: '#',
//                text: 'Customer Support',
//            },
//            {
//                url: '#',
//                text: 'About Us',
//            },
//            {
//                url: '#',
//                text: 'Copyright',
//            },
//            {
//                url: '#',
//                text: 'Popular Campaign',
//            },
//        ],
//    },
//    {
//        title: 'Our Information',
//        menuItems: [{
//                url: '#',
//                text: 'Return Policy',
//            },
//            {
//                url: '#',
//                text: 'Privacy Policy',
//            },
//            {
//                url: '#',
//                text: 'Terms & Conditions',
//            },
//            {
//                url: '#',
//                text: 'Site Map',
//            },
//            {
//                url: '#',
//                text: 'Store Hours',
//            },
//        ],
//    },
//    {
//        title: 'My Account',
//        menuItems: [{
//                url: '#',
//                text: 'Press inquiries',
//            },
//            {
//                url: '#',
//                text: 'Social media directories',
//            },
//            {
//                url: '#',
//                text: 'Images & B-roll',
//            },
//            {
//                url: '#',
//                text: 'Permissions',
//            },
//            {
//                url: '#',
//                text: 'Speaker requests',
//            },
//        ],
//    },
//    {
//        title: 'Policy',
//        menuItems: [{
//                url: '#',
//                text: 'Application security',
//            },
//            {
//                url: '#',
//                text: 'Software principles',
//            },
//            {
//                url: '#',
//                text: 'Unwanted software policy',
//            },
//            {
//                url: '#',
//                text: 'Responsible supply chain',
//            },
//        ],
//    },
//];

export const FEATURES = [{
  icon: feature1,
  title: 'Holistic Profile Building',
  description: 'Our assessment offers a 360-degree view of your profile - Academics, Standardized Test Scores, Extracurricular Activities.',
},
{
  icon: feature2,
  title: 'Your Readiness Score',
  description: 'Receive detailed feedback. Understand where you stand and what it takes to elevate your profile to the next level.',
},
{
  icon: feature3,
  title: 'Actionable Insights',
  description: 'Post-assessment, gain valuable insights and actionable steps to enhance your readiness for studying abroad.',
},
];

//export const SCREENSHOTS = [{
//        icon: ic_monetization_on,
//        title: 'Budget Overview',
//        image: Screenshot1,
//    },
//    {
//        icon: ic_settings,
//        title: 'Create & Adjust',
//        image: Screenshot1,
//    },
//    {
//        icon: pieChart,
//        title: 'View Reports',
//        image: Screenshot1,
//    },
//    {
//        icon: briefcase,
//        title: 'Integrations',
//        image: Screenshot1,
//    },
//];

export const TESTIMONIALS = [
  {
    title: 'About The Test',
    review: (
      <>
        <br /><br /> <br />
        {/*<img style={ { paddingLeft: '60px', paddingTop: '15px' } } src={ testiImage } alt="Testimonial Image" />*/ }
        <strong>• Text Format : </strong> 15 questions <br /> <br />
        <strong>• Question Format : </strong> MCQ <br /> <br />
        <strong>• Duration : </strong> 5 Minutes <br /> <br />
        <strong>• Level :</strong> Begineer as well as Intermediate<br /> <br />
        <br /> <br /><br />
        The test offers a series of thought-provoking questions, crafted to evaluate essential competencies such as your communication skills,
        strategic thinking abilities, and leadership qualities.
      </>
    ),
    name: 'Jon Doe',
    designation: 'CEO of Dell Co.',
    avatar: AuthorOne,
  },
  {
    title: 'Who is This Test for?',
    review: (
      <>
        <strong>• Aspiring Managers:</strong> For those dreaming of leading teams and driving business success.<br /> <br />
        <strong>• Career Changers:</strong> Considering a switch to management? This test will guide your decision.<br /> <br />
        <strong>• Recent Graduates:</strong> Fresh out of college and pondering a management career? Start here.<br /> <br />
        <strong>• Entrepreneurial Minds:</strong>If you're planning to start your own business, assess your management acumen.<br /> <br />
        <strong>• Professionals Seeking Growth:</strong>Elevate your career by understanding your potential in management roles.<br /> <br />
        <strong>• Curious Minds:</strong>Anyone curious about what a management career entails.
      </>
    ),
    name: 'Jon Doe',
    designation: 'Co Founder of IBM',
    avatar: AuthorTwo,
  },
];


export const USERTESTIMONIALS = [
  {
    review:
      'I am extremely grateful for the team at JenNext Mentors to have helped me enrol in the world’s top public policy course at a university that I believe will bring the best out of me. JenNext Mentors helped me view my application from the university point-of-view and focus on areas that required improvement.',
    name: 'Madhuri Sharma',
    designation: 'Master of Public Policy, University of Chicago',
    avatar: UniversityChicago,
  },
  {
    review:
      'JenNext Mentors provided me with such comprehensive assistance including one-one counselling, examinations, preparations, shortlisting universities and much more. I couldn\'t be more thankful as I am about start my undergraduate studies in September 2021 at Brown University. Thank you JenNext Mentors for everything!',
    name: 'Vedant Bhatt',
    designation: 'Liberal Arts, Brown University.',
    avatar: BrownUniversity,
  },
  {
    review:
      'JNM helped me build a great profile to apply to universities, which helped in secure an offer  from the LSE. Everyone at JenNext was extremely supportive and friendly throughout the process.',
    name: 'Devang Chaturvedi',
    designation: 'Economics, LSE',
    avatar: LSE,
  },
  {
    review:
      'JenNext Mentors helped me to their best possible level in achieving the desired academic results by getting me enrolled into Cornell University.  ',
    name: 'Aarushi Mehta',
    designation: 'Computer Science, Cornell University',
    avatar: cornell,
  },
];

export const UNIVERSITIES = [
  {
    avatar: uni1,
  },
  {
    avatar: uni2,
  },
  {
    avatar: uni3,
  },
  {
    avatar: uni4,
  },
  {
    avatar: uni5,
  },
  {
    avatar: uni6,
  },
  {
    avatar: uni7,
  },
  {
    avatar: uni8,
  },
  {
    avatar: uni9,
  },
  {
    avatar: uni10,
  },
  {
    avatar: uni11,
  },
  {
    avatar: uni12,
  },
  {
    avatar: uni13,
  },
  {
    avatar: uni14,
  },
  {
    avatar: uni15,
  },
  {
    avatar: uni16,
  },
  {
    avatar: uni17,
  },
  {
    avatar: uni18,
  },
  {
    avatar: uni19,
  },
];


export const ACCREDATIONS = [
  {
    avatar: acc1,
  },
  {
    avatar: acc2,
  },
  {
    avatar: acc3,
  },
  {
    avatar: acc4,
  },
  {
    avatar: acc5,
  },
  {
    avatar: acc6,
  },
  {
    avatar: acc7,
  },
  {
    avatar: acc8,
  },
];
