import React from 'react';
import Container from '../../common/components/UI/Container';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';

import { ContactUsWrapper } from './contactUs.style';

const ContactUs = () => {

  const navigateToTest = () => {
    window.open( 'https://assessment.jennextmentors.com/JenNextMentors/212?institute_id=368', '_blank' );
  };

  return (
    <ContactUsWrapper id="contact_section">
      <Container>
        <Box className="contactInner">
          <Box className="leftContent">
            <Heading as="h3" content="Start Shaping Your Future Today " />
            <Heading
              as="h3"
              className="color2"
              content="Ready to take the first step towards your international academic goals? Leverage years of experience and study-abroad expertise to unlock personalized guidance and strategic insights."
            />
          </Box>
          <Button onClick={ navigateToTest } className="contactBtn" title="Download Your Free Report!" />
        </Box>
      </Container>
    </ContactUsWrapper>
  );
};

export default ContactUs;
