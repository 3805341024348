import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import GlideCarousel from '../../common/components/GlideCarousel';
import GlideSlide from '../../common/components/GlideCarousel/glideSlide';
import { TESTIMONIALS } from '../../data/index';
import aboutTheTestImage from '../../common/assets/OBJECTS.png';
import {
  TestimonialItem,
  WrapperRow,
  ImageWrapper,
} from './testimonial.style';

const TestimonialSection = ( {
  sectionWrapper,
  reviewTitle,
  review,
  sectionWrapperRow,
  image,
  sectionWrapperCol,
} ) => {

  return (
    <Box { ...sectionWrapper } as="section" id="testimonial_section">
      <Container>
        <WrapperRow>
          <>
            {/*{ TESTIMONIALS.map( ( item, index ) => (
              <Box { ...sectionWrapperCol } key={ `testimonial-slide-${ index }` }>
                <TestimonialItem>
                  <Heading as="h3" content={ item.title } { ...reviewTitle } />
                  <Text content={ item.review } { ...review } />
                </TestimonialItem>
              </Box>
            ) ) }*/}
            <Box { ...sectionWrapperCol } key={ `testimonial-slide-1` }>
              <TestimonialItem>
                <Heading as="h3" content='About Us' { ...reviewTitle } />
                <Text content={ <>Since 2004, JenNext has excelled in providing personalized mentorship for overseas student applications. We treat each student uniquely, with a deep understanding of global application standards for top institutes. Our approach is tailored to fit individual profiles and aspirations, resulting in significant achievements: annually, we facilitate over 300 student admissions and secure more than 500 offer letters. These results underscore our commitment to each student's success in their international educational journey.</> }
                  { ...review } />
                <ImageWrapper>
                  <img src={ aboutTheTestImage } />
                </ImageWrapper>
                {/*<Text content={ <><img src={ aboutTheTestImage } style={ { width: '100px', height: '100px' } } /></> } { ...review } />*/ }
              </TestimonialItem>
            </Box>
            <Box { ...sectionWrapperCol } key={ `testimonial-slide-2` }>
              <TestimonialItem>
                <Heading as="h3" content={ <>Here's how we level up your journey to success:</> } { ...reviewTitle } />
                <Text content={ <><strong> Build a Top-notch Profile.<br /></strong>• Enhance your profile by highlighting achievements and filling gaps through introspective analysis.<br />• Simplify your application process with expert insights and step-by-step guidance.<br /><br /><br /> </> } { ...review } />
                <Text content={ <><strong> Highlight Extracurricular Activities. <br /></strong>• We provide opportunities to enrich your extracurricular experiences, demonstrating your global readiness. <br />• Showcase extensive involvement and leadership as standout qualities, setting you apart from other applicants.<br /><br /><br /></> } { ...review } />
                <Text content={ <><strong>Enhance Academic Performance.<br /></strong>• We provide tailored roadmap to ensure you're ready for academic success in your chosen destination.<br />• Receive comprehensive support for entrance exams like GRE, TOEFL, or IELTS to enhance your confidence and performance.</> } { ...review } />
              </TestimonialItem>
            </Box>

          </>
        </WrapperRow>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapperRow: PropTypes.object,
  sectionWrapperCol: PropTypes.object,
  image: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '0px'],
    pb: ['60px', '80px', '90px', '100px', '60px'],
  },
  image: {
    pt: ['5px', '10px', '20px', '20px', '20px'],
  },
  sectionWrapperRow: {
    display: 'flex',
    flexwrap: 'wrap',
    ml: '-20px',
    mr: '-20px',
  },
  secTitleWrapper: {
    mb: ['40px', '60px'],
  },
  sectionWrapperCol: {
    flex: '1 1 33.333%',
    pl: '20px',
    pr: '20px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#ff4362',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '500px',
    maxWidth: '100%',
  },
  reviewTitle: {
    fontSize: ['15px', '24px'],
    fontWeight: '800',
    color: '#CD121B',
    lineHeight: '1.5',
    mb: '20px',
  },
  review: {
    fontSize: ['16px', '19px'],
    fontWeight: '300',
    color: '#343d48',
    lineHeight: '1.7',
    mb: 0,
  },
  name: {
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '8px',
  },
  designation: {
    fontSize: ['12px', '14px'],
    color: '#6f7a87',
    mb: 0,
  },
};

export default TestimonialSection;
